function menu() {
    $('.menu').click(function () {
        if (!$('html').hasClass('menu-open') && !$('.backdrop').hasClass('menu-open')) {
            $('html').addClass('menu-open');
            bd.fadeIn();
        } else {
            $('html').removeClass('menu-open');
            bd.fadeOut();
        }
    });
}

function submenu() {
    $('.navbar-nav li > a, .navbar-nav li > .navbar-clickable, .navbar-extra li > a').click(function (e) {
        if ($(this).parent().find('.submenu').length) {
            e.preventDefault();
            $('.popup').removeClass('active');
        }


        if (!$(this).parent().find('.submenu').hasClass('sys')) {
            $('.submenu').removeClass('sys');
            $(this).parent().find('.submenu').addClass('sys');
            bd.removeAttr('style').addClass('menu-open').show();
        } else {
            $(this).parent().find('.submenu').removeClass('sys');
            bd.removeClass('menu-open').hide();
        }
    });

    /*$('.li-country a').click(function(e) {
        e.preventDefault();
        $('.submenu-country').addClass('sys').css({
            'left': ($(this).offset().left - $('.submenu-country').width()) + 4
        });
    });*/
}

function form() {
    var donablyUrl = window.parent.location.origin;

    $('.form-group').off().on('click', function (e) {
        var self = $(this);

        if (!$(e.target).hasClass('tooltip-trigger')) {
            if ($(self).find('input#organization').length) {
                if ($(self).find('input#organization').prop('checked') == true) {
                    $('.form-group-organization').slideDown();
                    $('.form-group-organization input[type="text"]').attr('required', 'required');
                } else {
                    $('.form-group-organization').slideUp();
                    $('.form-group-organization input[type="text"]').removeAttr('required');
                }
            }
            var self = $(this);
            setTimeout(function () {
                $(self).addClass('active');
            }, 10);
        }
    });

    $(doc).on('click', '.form-control', function () {
        if (!$(this).parent().hasClass('active')) {
            $('.form-group').removeClass('active');
            $(this).parent().addClass('active');
        }
    }).on('keyup', '.form-control', function () {
        if ($(this).val() != '') {
            $(this).parent().addClass('has-value');
        } else {
            $(this).parent().removeClass('has-value');
        }
    }).on('focusout', '.form-control', function () {
        var attr = $(this).attr('required');

        if ($(this).val() == '') {
            if (attr) {
                $(this).parent().addClass('has-error');
            }

            $(this).parent().removeClass('active has-value');
        } else {
            $(this).parent().removeClass('active has-error');
        }
    });

    $('.form-control').each(function (i, v) {
        if ($(v).val().length >= 1 || $(v).text() >= 1) {
            $(v).trigger('change');
            $(v).parent().addClass('has-value').removeClass('active');
        }
    });

    function formatState(state) {
        if (!state.id) {
            return state.text;
        }
        var baseUrl = "../public/img/dev";
        var $state = $('<span><img src="' + baseUrl + '/flag-' + state.element.value.toLowerCase() + '.svg" class="img-flag" /> ' + state.text + '</span>');
        return $state;
    }

    $('.form-control-select').not('.form-control-pw').each(function (i, v) {
        $(v).attr('class', 'form-control-select form-control-select-' + i);

        if ($(v).data('spec') == 'image') {
            $(v).select2({
                containerCssClass: 'asd', dropdownCssClass: $('.form-control-select-' + i).data('customclass'), language: lang, templateResult: formatState
            }).on('select2:open', function () {
                if ($('.form-countrycode').length) {
                    $('.form-countrycode').parent().addClass('select2-container-countrycode');
                }
            }).on('select2:select', function () {
                $('.form-control-select-' + i).parents('.form-group').addClass('has-value').removeClass('active has-error');
            }).on('focus');
        } else {
            $(v).select2({
                dropdownCssClass: $('.form-control-select-' + i).data('customclass'), width: '100%', language: lang,
            }).on('select2:select', function () {
                $('.form-control-select-' + i).parents('.form-group').addClass('has-value').removeClass('active has-error');

                if ($('#socials').length && $(this).attr('id') == 'socials') {
                    var label = $('#socials').val(), number = $('body').find('.clone-area .form-group .form-control').length + 1, url = $(this).find('option:selected').data('url');

                    $('#socials').parent().slideUp();
                    $('.clone-area').append('<div class="form-group form-group-full form-group-split hys has-value"> ' + '<label class="form-label" for="added-input-' + number + '">' + label + '</label> ' + '<p>' + url + '</p>' + '<input type="text" class="form-control" name="added-input-' + number + '" id="added-input-' + number + '" required/> ' + '<span class="delete-url delete-added-url"><img src="' + donablyUrl + '/public/img/icons/delete-red.svg" width="45" height="45" alt="Törlés" title="Törlés"/></span>' + '</div>');

                    $('#added-input-' + number).focus();
                }
            }).on('select2:close', function () {
                if ($('#socials').length) {
                    setTimeout(function () {
                        $('#socials').val(null).trigger('change');
                    }, 100);
                }
            }).on('focus');
        }
    });

    $('body').off().on('click', '.select2.select2-container', function () {
        $(this).parent().find('.form-control-select').select2('focus');
    });

    $(doc).on('click', '.form-label-select', function () {
        $(this).parent().find('.form-control-select').select2('open');
    });
}

function datepicker() {
    $('.datepicker').datepicker({
        language: lang, autoclose: true, format: 'yyyy. mm. dd.', weekStart: 1, orientation: 'bottom', todayHighlight: true
    });
}

function paywallBilling() {
    if ($('.paywall-taxnumber').length) {
        var taxFormat = $('.paywall-taxnumber').data('numberformat');
        $('.paywall-taxnumber').mask(taxFormat);
    }
}

function createNotification(text, source) {
    $('body').append('<div class="notification notification-success">' + '<img src="../public/img/icons/notification.svg" width="45" height="40" alt="" title=""/>' + '<p>' + text + '</p>' + '</div>');

    setTimeout(function () {
        $('.notification').addClass('sys');
    }, 100);
    setTimeout(function () {
        $(source).removeClass('disable');
    }, 2000);
    setTimeout(function () {
        $('.notification').remove();
    }, 3000);
}

function createPopup(title, text, button) {
    $('body').append('<div class="tr-600 popup popup-general"> ' + '<div class="popup-inner">' + '<div class="popup-close"><img src="../public/img/icons/close.svg" width="34" height="34" alt="" title=""/></div> ' +

        '<div class="popup-content"> ' + '<h2 class="h1 text-center">' + title + '</h2> ' + '<p>' + text + '</p> ' + '<a href="javascript:void(0)" class="btn btn-turquoise btn-turquoise-mini btn-anim popup-dismiss" title="' + button + '">' + '<span class="bg"></span>' + '<span class="text">' + button + '</span>' + '</a>' + '</div> ' + '</div>');

    showPopup('general');

}

function createDismissableNotification(text) {
    let pageUrl = $('base').attr('href');

    $('body').append('<div class="notification notification-dismiss notification-success">' + '<img src="' + pageUrl + '/public/img/icons/notification-close.svg" class="notification-close" width="37" height="37" alt="" title=""/>' + '<img src="' + pageUrl + '/public/img/icons/notification.svg" class="notification-icon" width="45" height="40" alt="" title=""/>' + '<p>' + text + '</p>' + '</div>');

    setTimeout(function () {
        $('.notification').addClass('sys');
    }, 100);
}

function closeNotification() {
    $(doc).on('click', '.notification-close', function () {
        $('.notification-dismiss').addClass('hys');

        setTimeout(function () {
            $('.notification-dismiss').remove();
        }, 3000);
    });
}

function showCustomBlock() {
    $('.js-showcustomblock').change(function () {
        if ($(this).prop('checked') == true) {
            $('.custom-blocktemplate').show();
        } else {
            $('.custom-blocktemplate').hide();
        }
    });

    $('.js-hide-custom').click(function () 
    {
        $('.js-showcustomblock').prop('checked', false).trigger('change');
        //showPopup('hidepackage');
    })

    if ($('.js-hide-package').length) {
        $('.js-hide-package').click(function (e) 
        {
            //e.preventDefault();
            $('.js-showcustomblock').prop('checked', false).trigger('change');
        })
    }

}

function accordion() {
    $(doc).on('click', '.gyik-item-title', function () {
        var self = $(this);

        if (!$(this).hasClass('active')) {
            $(this).parent().find('.gyik-item-title, .gyik-item-txt').removeClass('active');
            $(this).parent().find('.gyik-item-txt').slideUp();
            $(self).addClass('active');
            $(self).next().addClass('active ').slideDown();
        }
    });

    $('.gyik-item-title').each(function (i, v) {
        if ($(v).hasClass('active')) {
            $(v).next().addClass('active').show();
        }
    });
}

function addUrl() {
    $('.add-page').click(function () {
        $(this).parent().find('.input-urls').slideDown(function () {
            $('#socials').select2('open');
        });
    });
}

function deleteAddedUrl() {
    $(doc).on('click', '.delete-added-url', function () {
        $(this).parents('.form-group-split.hys').remove();
    });
}

function checkInputContent() {
    $('.input-check').keyup(function () {
        var self = $(this), selfVal = $(self).val(), target = $(self).data('target'), textTarget = $(self).data('texttarget');

        if ($(self).val() != '') {
            if (textTarget) {
                $(textTarget).text(selfVal);
            }
            if (target) {
                $(target).find('.edit').addClass('edited');
            } else {
                $(self).parent().find('.edit').addClass('edited');
            }
        } else {
            if (textTarget) {
                $(textTarget).html('&nbsp;');
            }
            if (target) {
                $(target).find('.edit').removeClass('edited');
            } else {
                $(self).parent().find('.edit').removeClass('edited');
            }
        }
    }).each(function () {
        var self = $(this), selfVal = $(self).val(), target = $(self).data('target'), textTarget = $(self).data('texttarget');

        if ($(self).val() != '') {
            if (textTarget) {
                $(textTarget).text(selfVal);
            }
            if (target) {
                $(target).find('.edit').addClass('edited');
            } else {
                $(self).parent().find('.edit').addClass('edited');
            }
        }
    })
}

function focusInputContent() {
    $('.edit').click(function () {
        var self = $(this), target = $(self).data('target');

        if (target) {
            $('body').animate({'scrollTop': $('#' + target).parent().offset().top - $('.navbar').height() - 60}, '1000');
            $('#' + target).parent().find('input').focus();
        } else {
            $(self).parents('.input-group').find('.input-check').focus();
        }
    })
}

function checkPassword() {
    $('input[type=password]').keyup(function () {
        var strength = 0;

        var val = $(this).val(), arr = [/.{8,}/, /[a-z]+/, /[0-9]+/, /[A-Z]+/];

        jQuery.map(arr, function (regexp) {
            if (val.match(regexp)) strength++;
        });

        $('.indicator').removeClass('active');
        for (i = 0; i < strength; i++) {
            $('.check-password .indicator').eq(i).addClass('active');
        }
    });
}

function inputFocus() {
    $(document).on('paste', '#smscode input', function (e) {
        var copiedText = e.originalEvent.clipboardData.getData("text/plain"), isNumberCopiedText = $.isNumeric(copiedText);
        textArr = [];

        if (isNumberCopiedText) {
            for (var i = 0; i < copiedText.length; i++) {
                textArr.push(copiedText.charAt(i));
            }

            $('#smscode input').each(function (i, v) {
                $(v).val(textArr[i]);
            });

            setTimeout(function () {
                $('#smscode input:last-child').focus();
            }, 100);
        }
    });

    $(document).on('paste', '#emailcode input', function (e) {
        var copiedText = e.originalEvent.clipboardData.getData("text/plain"), isNumberCopiedText = $.isNumeric(copiedText);
        textArr = [];

        if (isNumberCopiedText) {
            for (var i = 0; i < copiedText.length; i++) {
                textArr.push(copiedText.charAt(i));
            }

            $('#emailcode input').each(function (i, v) {
                $(v).val(textArr[i]);
            });

            setTimeout(function () {
                $('#emailcode input:last-child').focus();
            }, 100);
        }
    });

    $('#smscode .input-group-code .input-field').keyup(function (e) {
        if (!$.isNumeric($(this).val()) && e.which != '8') {
            $(this).val('');
            return false;
        } else {
            if (e.which == '8') {
                if ($(this).parent().prev('.input-group-code').length) {
                    $(this).parent().prev('.input-group-code').find('.input-field').focus();
                }
            } else {
                if ($(this).parent().next('.input-group-code').length) {
                    $(this).parent().next('.input-group-code').find('.input-field').focus();
                }
            }
        }
    });

    $('#emailcode .input-group-code .input-field').keyup(function (e) {
        if (!$.isNumeric($(this).val()) && e.which != '8') {
            $(this).val('');
            return false;
        } else {
            if (e.which == '8') {
                if ($(this).parent().prev('.input-group-code').length) {
                    $(this).parent().prev('.input-group-code').find('.input-field').focus();
                }
            } else {
                if ($(this).parent().next('.input-group-code').length) {
                    $(this).parent().next('.input-group-code').find('.input-field').focus();
                }
            }
        }
    });
}

function copyValue() {
    var target = $('.channelurl');

    $('.input-copy').on('keyup', function () {
        var inputValue = $(this).val();
        target.text(RemoveAccents(inputValue).replace(/\s+/g, '').toLowerCase());
    });

    $('.input-copy').each(function () {
        var inputValue = $(this).val();
        target.text(RemoveAccents(inputValue).replace(/\s+/g, '').toLowerCase());
    });
}

function dzAudioURL() {
    $('#page-writepost-audio-url').on('keyup', function () {
        /*
        http://ss.ngdev.wi24.hu/sample.mp3
        https://donably.wi24.hu/public/upload/temp/mp3/103718.mp3
        */
        var fileExtension = ['mp3'];
        if ($.inArray($(this).val().split('.').pop().toLowerCase(), fileExtension) == -1) {

        } else {
            var sourcefile = $(this).val();
            $('audio.fc-media source').attr('src', sourcefile);
            dzMediaTag(sourcefile);
            dzRemoveIcon();
            audioplayer();
            $('.music-player').addClass('sys');
            $('.page-writepost-box-audio').addClass('show-player');
        }
    });

    $('.music-delete').click(function () {
        dzRemoveAudio();
        $('.music-player').removeClass('sys');
        $('.page-writepost-box-audio').removeClass('show-player');
        $('#page-writepost-audio-url').val('');
    });
}

function writePost() {
    /* Szövegszerkesztő magasság */
    $('.writty-content').bind('DOMSubtreeModified', function () {
        var divHeight = $('.writty-content').height();
        $('.writty-editor').css('min-height', divHeight + 'px');
    });

    /* Dobozok megjelenítése */
    $('.page-writepost-button').click(function () {
        var target = $(this).attr('id');

        $('.page-writepost-button').removeClass('active');
        $(this).addClass('active');

        $('.page-writepost-box').removeClass('sys');
        $('.' + target).addClass('sys');

        switch (target) {
            case "page-writepost-box-image":
                if (!$('#dzupload-picture').hasClass('dz-clickable')) {
                    dropzonePictureUpload();
                }
                break;
            case "page-writepost-box-audio":
                if (!$('#dzupload-audio').hasClass('dz-clickable')) {
                    dropzoneAudioUpload();
                }
                break;
        }
    });

    /* Kép feltöltés */
    $("#page-writepost-uploadimage").click(function () {
        writePostCloseAll();
    });

    $('.page-writepost-preview-close').click(function (e) {
        e.preventDefault();

        writePostClosePicture();
    });

    $('#page-writepost-youtube').click(function (e) {
        e.preventDefault();

        writePostCloseAll()

        $('.page-writepost-youtube-input').show().trigger('click');
        $('#page-writepost-youtube-url').focus();
    });

    /* Youtube*/
    $('#page-writepost-youtube-url').bind('keyup', function () {
        if (triggered == 0) {
            var targetURL = $(this).val(), targetSplit = targetURL.split('watch?v='), targetSplitMobil = targetURL.split('https://youtu.be/');

            if (targetSplit[1]) {
                targetYTID = targetSplit[1].slice(0, 11);
            }

            if (targetSplitMobil[1]) {
                targetYTID = targetSplitMobil[1].slice(0, 11);
            }

            var realWidth = '';

            if (targetYTID != '') {
                $('.page-writepost-youtube .page-writepost-preloader, .page-writepost-youtube').addClass('sys');
                $('.page-writepost-youtube .page-writepost-preview-youtube').attr('src', '');
                $('.page-writepost-youtube .page-writepost-preview-youtube').attr('src', 'https://i3.ytimg.com/vi/' + targetYTID + '/mqdefault.jpg').on('load', function () {
                    realWidth = this.width;
                });
                $('.page-writepost-youtube-input').removeClass('has-error has-value');

                $('#page-writepost-youtubevideoid').val(targetYTID);
                $('.page-writepost-youtube .page-writepost-preloader').removeClass('sys');
                $('.page-writepost-youtube .post-video-play').show();
            }

            triggered++;
        }
    }).change(function () {
        triggered = 0;
    });

    $('.page-writepost-youtube .post-video-play').on('click', function () {
        var target = targetYTID;

        writePostCloseAll();

        $('.page-writepost-youtube').hide();
        $('.page-writepost-box-youtube .post-video-iframe .post-video-iframe-youtube').append('<iframe src="https://www.youtube.com/embed/' + target + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');

        $('.page-writepost-box-youtube .post-video-iframe').show();
    });

    $('.page-writepost-youtube-close').click(function (e) {
        e.preventDefault();

        $('.page-writepost-youtube').show();
        $('.page-writepost-youtube-input').removeClass('has-error has-value');
        $('#page-writepost-youtube-url').val('');
        $('#page-writepost-youtubevideoid').val('');
        writePostCloseAll();
    });

    /* Vimeo */
    $('#page-writepost-vimeo-url').bind('keyup', function () {
        if (triggeredVimeo == 0) {
            var targetURL = $(this).val(), targetSplit = targetURL.split('/');

            // http://vimeo.com/api/v2/video/721894139.php

            targetVimeoID = +parseInt(targetSplit[3].slice(0, 9));

            $(".page-writepost-box-vimeo .page-writepost-error").hide();
            $(".page-writepost-preview-vimeo").attr("src", "https://vumbnail.com/" + targetVimeoID + ":3144c3edfc.jpg").attr("data-thumb", targetVimeoID);
            $(".page-writepost-box-vimeo .page-writepost-preloader, .page-writepost-box-vimeo .page-writepost-vimeo").addClass("sys");
            $("#page-writepost-vimeovideoid").val(targetVimeoID);
            $(".page-writepost-box-vimeo .page-writepost-preloader").removeClass("sys");
            $(".page-writepost-box-vimeo .post-video-play").show();
            triggeredVimeo++;

            var accessToken = '7e88d312a176fcf5ab1414191d655cd4';

            getVimeoThumbnail(targetVimeoID, accessToken);

            $('.page-writepost-vimeo').hide();
            $('.post-video-iframe .post-video-iframe-vimeo').append('<iframe src="https://player.vimeo.com/video/' + targetVimeoID + '?h=aa2c6de7ab&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>');

            $('.page-writepost-box-vimeo .post-video-iframe').show();
        }
    }).change(function () {
        triggeredVimeo = 0;
    });

    $('.page-writepost-vimeo .post-video-play').on('click', function () {
        var target = targetVimeoID;

        writePostCloseAll();

        $('.page-writepost-vimeo').hide();
        $('.post-video-iframe .post-video-iframe-vimeo').append('<iframe src="https://player.vimeo.com/video/' + target + '?h=aa2c6de7ab&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>');

        $('.page-writepost-box-vimeo .post-video-iframe').show();
    });

    $('.page-writepost-vimeo-close').click(function (e) {
        e.preventDefault();

        $('.page-writepost-vimeo').show();
        $('.page-writepost-vimeo-input').removeClass('has-error has-value');
        $('#page-writepost-vimeo-url').val('');
        $('#page-writepost-vimeovideoid').val('');
        writePostCloseAll();
    });

    $('.writty-content').click(function () {
        if ($('.writty-starttext').is(':visible')) {
            $('.writty-starttext').remove();

            $('#format-p').trigger('click');
        }
    }).keyup(function (e) {
        if (!$(this).find('p').length && $(this).text() != '') {
            $('#format-p').trigger('click');
        }

        if (e.which == 13) {
            if ($(e.currentTarget.lastElementChild).is('blockquote') || $(e.currentTarget.lastElementChild).is('h3')) {
                $('#format-p').trigger('click');
            }
        }
    });

    $('.toolbar-image').click(function () {
        $('label[for="imageUpload"]').trigger('click');
    });

    $('#format-h2').click(function () {
        $(this).hide();
        $('#format-p').show();
    });

    $('#format-p').click(function () {
        $(this).hide();
        $('#format-h2').show();
    });

    /* Checkboxok */
    $('.page-writepost input[type="radio"]').change(function () {
        $(this).parents('.chk-parent').find('.chk-content').slideUp();
        $(this).parents('.form-radio').find('.chk-content').slideDown();

    });

    $('.page-writepost input[type="checkbox"]').change(function () {
        if ($(this).prop('checked') == true) {
            if ($(this).parents('#chamlist').hasClass('has-error')) {
                $(this).parents('#chamlist').removeClass('has-error');
            }
        }
    });
}

function getVimeoThumbnail(videoId, userAccessToken) {
    $.ajax({
        url: 'https://api.vimeo.com/videos/' + videoId,
        type: 'GET',
        beforeSend: function (xhr) {
            xhr.setRequestHeader('Authorization', 'Bearer ' + userAccessToken);
        },
        success: function (response) {
            // A válaszból kinyerjük a borítókép URL-jét
            var thumbnailUrl = response.pictures.sizes[0].link;
            console.log('Borítókép URL: ', thumbnailUrl);

            // Itt megjeleníthetjük a borítóképet valahol a weboldalon
            $('#thumbnail').attr('src', thumbnailUrl);
        },
        error: function (xhr, status, error) {
            // Hiba esetén itt kezeljük le
            console.error('Hiba történt a Vimeo API hívás során: ', status, error);
        }
    });
}


function writePostSend() {
    $('.btn-sendpost').click(function (e) {
        var image = $('#page-writepost-uploadimage').val(), video = $('#page-writepost-youtubevideoid').val(), title = $('#page-writepost-title').val(), content = $('.writty-content').html();

        $('.post-debug .post-debug-form').html(image + '<br/>' + video + '<br/>' + title);
        $('.post-debug .post-debug-content').text(content);
    });
}

function writePostClosePicture() {
    $('.page-writepost-preview').hide();
    $('.page-writepost-preview .page-writepost-preview-image').attr('src', '');
}

function writePostCloseYoutube() {
    $('.page-writepost-youtube .page-writepost-preview-youtube').attr('src', '');
    $('.page-writepost-youtube').removeClass('sys sys-full');
    $('#page-writepost-youtube-url').val('');
    $('#page-writepost-youtubevideoid').val('');
    triggered = 0;
}

function writePosteCloseIframe() {
    $('.page-writepost-box-youtube .post-video-iframe iframe').remove();
    $('.page-writepost-box-youtube .post-video-thumbnail iframe').remove();
    $('.page-writepost-box-youtube .post-video-iframe, .page-writepost-box-youtube .post-video-play').hide();
    $('.page-writepost-box-youtube .post-video-thumbnail .page-writepost-preview-youtube').attr('src', '').show();

}

function writePostCloseVimeo() {
    $('.page-writepost-vimeo .page-writepost-preview-vimeo').attr('src', '');
    $('.page-writepost-vimeo').removeClass('sys sys-full');
    $('#page-writepost-vimeo-url').val('');
    $('#page-writepost-vimeovideoid').val('');
    triggeredVimeo = 0;
}

function writePosteCloseIframeVimeo() {
    $('.page-writepost-box-vimeo .post-video-iframe iframe').remove();
    $('.page-writepost-box-vimeo .post-video-thumbnail iframe').remove();
    $('.page-writepost-box-vimeo .post-video-iframe, .page-writepost-box-vimeo .post-video-play').hide();
    $('.page-writepost-box-vimeo .post-video-thumbnail .page-writepost-preview-vimeo').attr('src', '').show();
}

function writePostCloseAll() {
    if ($('.page-writepost-preview').is(':visible')) {
        writePostClosePicture();
        writePosteCloseIframe();
        writePostCloseVimeo();
        writePosteCloseIframeVimeo();
    }

    if ($('.page-writepost-youtube').is(':visible')) {
        writePostCloseYoutube();
        writePosteCloseIframe();
    }

    if ($('.page-writepost-vimeo').is(':visible')) {
        writePostCloseVimeo();
        writePosteCloseIframeVimeo();
    }

    /*if ($('.post-video-iframe').is(':visible')) {
        writePosteCloseIframe();
    }*/
}

function readImageURL(input) {
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $('.page-writepost-preview').show();
            $('.page-writepost-preview .page-writepost-preview-image').attr('src', e.target.result);
        }

        reader.readAsDataURL(input.files[0]); // convert to base64 string
    }
}

function tooltip() {
    $('.tooltip-trigger').click(function () {
        var $this = $(this), varText = $this.data('text'), extraClass = "", leftPos;

        if ($('.tab').length) {
            extraClass = "tooltip-paywall"
        }

        $('html').append($('<div class="tooltip ' + extraClass + '"/>')
            .append($('<div class="tooltip-inner"/>')
                .append($('<div class="tooltip-content"/>')
                    .html(varText))));

        var varTextHeight = $('.tooltip').not('.tooltip-sys').height();


        setTimeout(function () {
            leftPos = $this.offset().left - ($('.tooltip').width() - 20);

            if (leftPos < 0) {
                leftPos = 15;
            }

            $('.tooltip').not('.tooltip-sys').css({
                'top': $this.offset().top - (varTextHeight + $this.height()), 'left': leftPos, 'z-index': '999'
            });
        }, 100);

        bd.removeAttr('style').addClass('menu-open').show();
    });
}

function cretorSlider() {
    $('.creator-slider').slick({
        infinite: false,
        draggable: false, slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        centerPadding: '30px',
        speed: 400,
        responsive: [
            {
                breakpoint: 991, settings: {
                    slidesToShow: 2
                }
            }, {
                breakpoint: 768, settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}

function donationSlider() {
    $('.fanpage-donations-slider').on('init', function (i, c) {
        resizeBoxes('.fanpage-donations-slider .fanpage-box-title');

    }).slick({
        infinite: false,
        draggable: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: true,
        dots: false,
        speed: 400,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    draggable: true
                }
            }, {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                    dots: true,
                    draggable: true
                }
            }
        ]
    });
}

function dashboardSlider() {
    $('.dashboard-slider').slick({
        infinite: false, draggable: false, arrows: false, speed: 0
    });
}

function dashboardSliderGoTo(slideno) {
    var realId = $('#slider-' + slideno).data('slick-index');

    $('.dashboard-slider').slick('slickGoTo', realId);
}

function dashboardSliderReinit() {
    $('.dashboard-slider').slick('unslick');
    $('.dashboard-slider #slider-barion').remove();
    $('.dashboard-slider').slick({
        infinite: false, draggable: false, arrows: false, speed: 0

    });
}

function sendMessage() {
    $('input[name="for-who"]').on('change', function () {
        if ($(this).attr('id') == 'depends-on-support') {
            $('#form-support .form-group').slideDown();
            $('#form-support .form-group input').attr('required', 'required');
        } else {
            $('#form-support .form-group').slideUp();
            $('#form-support .form-group input').removeAttr('required');
            $('#form-support .form-group input').prop('checked', false);
        }
    });
}

function fanpageDonationList() {
    var boxNumber = $('.fanpage-donations .fanpage-box').length;

    if (boxNumber >= 4) {
        $('.btn-showall').addClass('sys');
    }

    $('body').on('click', '.btn-showall', function () {
        if ($(this).parents('.popup-content').length) {
            $(this).parents('.popup-content').find('.fanpage-donations-more').slideDown();
            $(this).parents('.popup-content').find('.btn-showless').addClass('sys');
        }
        if ($(this).parents('.page-fanpage').length) {
            $(this).parents('.page-fanpage').find('.fanpage-donations-more').slideDown();
            $(this).parents('.page-fanpage').find('.btn-showless').addClass('sys');
        }
        $(this).removeClass('sys');
    });

    $('body').on('click', '.btn-showless', function () {
        if ($(this).parents('.popup-content').length) {
            $(this).parents('.popup-content').find('.fanpage-donations-more').slideUp();
            $(this).parents('.popup-content').find('.btn-showall').addClass('sys');
        }
        if ($(this).parents('.page-fanpage').length) {
            $(this).parents('.page-fanpage').find('.fanpage-donations-more').slideUp();
            $(this).parents('.page-fanpage').find('.btn-showall').addClass('sys');
        }

        $(this).removeClass('sys');

    });
}

function fanpageDescription() {
    $('.fanpage-donations .fanpage-box .fanpage-description').each(function (i, v) {
        var moreText = $(v).data('more'), lessText = $(v).data('less');

        if ($(v).height() >= 165) {
            $(v).addClass('hys');
            $(v).append('<button class="show-more h4">' + moreText + '</button>');
            $(v).append('<button class="show-less h4 hys">' + lessText + '</button>');

            setTimeout(function () {
                $('.fanpage-donations .fanpage-box .fanpage-description').height('170');
            }, 100);
        }
    }).promise().done(function () {
        $('.fanpage-donations-more').hide();
    });

    $('.show-more').on('click', function () {
        var el = $(this), elCurrentHeight = el.parent().height(), elAutoHeight = el.parent().css('height', 'auto').height();
        el.parent().height(elCurrentHeight).animate({height: elAutoHeight}, 400);
        el.parent().addClass('sys');
        el.hide();
        setTimeout(function () {
            el.parent().find('.show-less').fadeIn();

        }, 300);
    });

    $('.show-less').on('click', function () {
        var el = $(this), elCurrentHeight = el.parent().height();

        el.parent().height(elCurrentHeight).animate({height: 170}, 400);
        el.parent().removeClass('sys');
        el.hide();

        setTimeout(function () {
            el.parent().find('.show-more').fadeIn();
        }, 300);
    });
}

function showFanpageShare() {
    $('.show-fanpageshare').click(function (e) {
        e.preventDefault();

        if (!$('.fanpage-submenu').hasClass('sys')) {
            $('.fanpage-submenu').addClass('sys');
            bd.addClass('menu-open').show();
        }
    });
}

function postList() {
    $('.post-list .post-item').each(function (i, v) {
        if ($(v).find('.music-player').length) {
            audioplayer();
        }
    });
}

function postReadmore() {
    $('.post-item .post-text').each(function (i, v) {
        var moreText = $(v).data('more'), lessText = $(v).data('less');

        if ($(v).height() >= 300) {
            $(v).addClass('hys');
            $(v).append('<button class="show-more h4">' + moreText + '</button>');
            $(v).append('<button class="show-less h4 hys">' + lessText + '</button>');
        }
    });

    $('.show-more').on('click', function () {
        var el = $(this), elCurrentHeight = el.parent().height(), elAutoHeight = el.parent().css('height', 'auto').height();

        el.parents('.post-item').addClass('sys');

        el.parent().height(elCurrentHeight).animate({height: elAutoHeight}, 400);
        el.parent().addClass('sys');
        el.hide();
        setTimeout(function () {
            el.parent().find('.show-less').fadeIn();

        }, 300);
    });

    $('.show-less').on('click', function () {
        var el = $(this), elCurrentHeight = el.parent().height();

        el.parent().height(elCurrentHeight).animate({height: 300}, 400);
        el.parent().removeClass('sys');
        el.hide();

        setTimeout(function () {
            el.parent().find('.show-more').fadeIn();
            el.parents('.post-item').removeClass('sys');
        }, 300);
    });
}

function postVideoEmbed() {
    $('body').on('click', '.post-video-thumbnail', function () {
        var target = $(this).data('code'), targetVimeo = $(this).data('vimeocode'), parentHeight = $(this).find('.img-resp').height();

        $(this).find('.page-writepost-preview-youtube').hide();

        if ($(this).parents('.post-item').length || $(this).parents('.readpost-header').length) {
            $(this).find('img.img-resp').remove();
            $(this).find('.post-video-play').remove();
        }
        if (!$(this).find('iframe').length) {
            if (target != '' && targetVimeo == '') {
                $(this).append('<iframe src="https://www.youtube.com/embed/' + target + '" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" height="' + parentHeight + '" allowfullscreen></iframe>')
            }

            if (targetVimeo != '' && target == '') {
                $(this).append('<div class="post-video-iframe-vimeo" style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/' + targetVimeo + '?h=aa2c6de7ab&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe></div>');
            }
        }
    });
}

function postVideoThumbnail() {
    $('.post-item .post-video-thumbnail').each(function (i, v) {
        var ytID = $(v).data('code'), realWidth = '', vimeoID = $(v).data('vimeocode');

        if (ytID) {
            $(v).find('.img-resp').attr('src', 'https://i3.ytimg.com/vi/' + ytID + '/mqdefault.jpg').on('load', function () {
                realWidth = this.width;
            });
        }

        if (vimeoID) {
            $.ajax({
                type: 'GET',
                url: 'https://vimeo.com/api/v2/video/' + vimeoID + '.json',
                jsonp: 'callback',
                data: 'json',
                success: function (data) {
                    var thumbnail_src = data[0].thumbnail_large;
                    $(v).find('.img-resp').attr('src', thumbnail_src);
                },
                error: function (xhr, ajaxOptions, thrownError) {
                    console.log(xhr.status);
                    console.log(thrownError);
                }
            });
        }
    });
}

function backdrop() {
    $('.backdrop').click(function () {
        $('.popup').removeAttr('style').removeClass('active');

        bd.fadeOut();
        if ($('.autocomplete').is(':visible')) {
            $('.autocomplete').hide();
        }

        if ($('html').hasClass('menu-open')) {
            $('html').removeClass('menu-open');
        }

        if ($('.submenu').hasClass('sys')) {
            $('.submenu').removeClass('sys')
        }

        if ($('.fanpage-submenu').hasClass('sys')) {
            $('.fanpage-submenu').removeClass('sys');
        }

        setTimeout(function () {
            bd.removeClass('sys menu-open').removeAttr('style');
            $('.popup').removeClass('higher-then-window');
        }, 400);

        if ($('.tooltip').length) {
            $('.tooltip').remove();
        }

        if ($('.picture-container-package').hasClass('croppie-container')) {
            $('.picture-container-package').croppie('destroy');
        }
    });
}

function popup() {
    $(doc).on('click', '.show-popup', function (e) {
        e.preventDefault();

        var target = $(this).data('target'), windowHeight = $(window).height(), popupHeight = $('.popup-' + target).outerHeight();

        $('.popup-' + target).removeClass('higher-then-window');

        if (popupHeight >= (windowHeight - 150)) {
            $('.popup-' + target).addClass('higher-then-window');

            setTimeout(function () {
                $('.popup-' + target).css('top', $(window).scrollTop() + $('.navbar').height() + 40);
            }, 200);
        }

        $('.popup').removeClass('active');
        bd.removeClass('menu-open');

        if ($('#package-pic-upload').length) {
            packagePicture();
        }

        $('.popup-' + target).addClass('active');
        $('.submenu').removeClass('sys');

        bd.fadeIn();

        if ($('html').hasClass('menu-open')) {
            $('html').removeClass('menu-open');
        }

        if ($(this).data('target') == 'reg' || $(this).data('target') == 'prereg-email') {
            setTimeout(function () {
                resizeBoxes('.form-reg');
            }, 500);
        }

    }).on('click', '.popup-close, .popup-dismiss, .popup-btnclose', function () {
        bd.fadeOut();
        $('.popup').removeClass('active');
        setTimeout(function () {
            bd.removeClass('sys');
        }, 400);

        if ($('.picture-container-package').hasClass('croppie-container')) {
            $('.picture-container-package').croppie('destroy');
        }

    }).on('click', '.popup-back', function () {
        if ($('.popup-reg').length) {
            $(this).parent().find('.forms.show-activation').removeClass('show-activation');
            $(this).removeClass('sys');

        }
    });
}

function showPopup(target) {
    var windowHeight = $(window).height(), popupHeight = $('.popup-' + target).outerHeight();

    $('.popup-' + target).removeClass('higher-then-window');

    if (popupHeight >= (windowHeight - 200)) {
        $('.popup-' + target).css('top', $(window).scrollTop() + $('.navbar').height() + 40).addClass('higher-then-window');
    }

    $('.popup').removeClass('active');
    $('.popup-' + target).addClass('active');

    bd.fadeIn();
}

function pageCompare() {
    $('.d-tab-item').click(function () {
        var target = $(this).data('target');

        $('.d-tab-item').removeClass('active');
        $(this).addClass('active');

        $('.d-tab-container').hide();
        $('#' + target).show();
    });
}

function pageDonations() {
    $('.show-older').click(function (e) {
        e.preventDefault();

        $('.donation-older .hys').removeClass('hys');
    });
}

function RemoveAccents(strAccents) {
    var strAccents = strAccents.split('');
    var strAccentsOut = new Array();
    var strAccentsLen = strAccents.length;
    var accents = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽžőű';
    var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZzou";
    for (var y = 0; y < strAccentsLen; y++) {
        if (accents.indexOf(strAccents[y]) != -1) {
            strAccentsOut[y] = accentsOut.substr(accents.indexOf(strAccents[y]), 1);
        } else strAccentsOut[y] = strAccents[y];
    }
    strAccentsOut = strAccentsOut.join('');
    return strAccentsOut;
}

function resizeBoxes(boxes) {
    if ($.type(boxes) === 'string') boxes = $(boxes);

    var h = 0;
    boxes.height('auto');
    boxes.each(function () {
        if ($(this).height() > h) h = $(this).height();
    });
    boxes.height(h);
}

function minResizeBoxes(boxes) {
    if ($.type(boxes) === 'string') boxes = $(boxes);

    var h = 0;
    boxes.minHeight('auto');
    boxes.each(function () {
        if ($(this).minHeight() > h) h = $(this).minHeight();
    });
    boxes.minHeight(h);
}

function getOS() {
    var a = '';
    var osString = {
        'windows': /(Win)/, 'android': /Android/, 'linux': /(Linux|X11)/, 'ios': /(iPhone|iPad|iPod|Mac)/, 'unix': /UNIX/, 'bot': /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
    };

    $.each(osString, function (k, v) {
        if (a == '' && v.test(navigator.userAgent)) a = k;
    });
    return a;
}

function getBrowser() {
    var a = '';
    var browserString = {
        'Chrome': 'chrome', 'MSIE': 'ie', 'Trident/': 'ie', 'Firefox': 'ff', 'Safari': 'safari', 'Presto': 'opera', 'Opera': 'opera'
    };
    $.each(browserString, function (k, v) {
        if (a == '' && navigator.userAgent.indexOf(k) != '-1') a = v;
    });
    return a;

}

/* apple-safari mobile rotation bug fixed */
if (os == 'ios' && browser == 'safari') {
    (function (doc) {
        var addEvent = 'addEventListener', type = 'gesturestart', qsa = 'querySelectorAll', scales = [1, 1], meta = qsa in doc ? doc[qsa]('meta[name=viewport]') : [];

        function fix() {
            meta.content = 'width=device-width,minimum-scale=' + scales[0] + ',maximum-scale=' + scales[1];
            doc.removeEventListener(type, fix, true);
        }

        if ((meta = meta[meta.length - 1]) && addEvent in doc) {
            fix();
            scales = [.25, 1.6];
            doc[addEvent](type, fix, true);
        }
    }(document));
}

/* windows phone 8.1 bug fixed */
if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement("style");
    msViewportStyle.appendChild(document.createTextNode("@-ms-viewport{width:auto!important}"));
    document.getElementsByTagName("head")[0].appendChild(msViewportStyle);
}

var runing = false;

/*
if (ifInPage($('.animated')) && !running) {
        $('.animated').each(function(i,v) {
            $(v).addClass('go');
        });
        running = true;
*/
var ifInPage = function isScrolledIntoView(elm, ezval) {
    ezval = ezval || "visible";
    var vpH = $(window).height(), // Viewport Height
        st = $(window).scrollTop(), // Scroll Top
        y = $(elm).offset().top, elementHeight = $(elm).height();

    if (ezval == "visible") return ((y < (vpH + st)) && (y > (st - elementHeight)));
    if (ezval == "above") return ((y < (vpH + st)));
}, animateScroll = function () {
    if (!runing) {
        if ($('#chart_anim').length > 0) {
            $('#chart_anim').each(function () {
                runing = true;

                if (ifInPage($(this))) {
                    var $this = $(this);

                    $this.addClass('s1');
                }
            });
        }

        if ($('.animated').length > 0) {
            $('.animated').each(function () {
                runing = true;

                if (ifInPage($(this))) {
                    var $this = $(this);
                    $this.addClass('go');
                }
            });
        }
        runing = false;
    }
}, runing = false;

var browser, os, WW, WH, DH, ST, baseURL, lazy, minW = 320, resizeTo, lang, doc = 'document,html,body', base = ($('base').length ? $('base').attr('href') : $('html').data('base')), lang = $('html').attr('lang'), d = false, previousTop = 0, opened = 0, runing, bd = $('.backdrop'), navbarHover = 0, lastScrollTop = '', justEmpty = '', scrolldown = 0, basketPageAppend = 0, running = false, triggered = 0, targetYTID = '', triggeredVimeo = 0, targetVimeoID = '';
browser = getBrowser();
os = getOS();

jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchstart", handle, {passive: !ns.includes("noPreventDefault")});
    }
};
jQuery.event.special.touchmove = {
    setup: function (_, ns, handle) {
        this.addEventListener("touchmove", handle, {passive: !ns.includes("noPreventDefault")});
    }
};
jQuery.event.special.wheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("wheel", handle, {passive: true});
    }
};
jQuery.event.special.mousewheel = {
    setup: function (_, ns, handle) {
        this.addEventListener("mousewheel", handle, {passive: true});
    }
};

$('.btn-anim').mousemove(function (e) {
    var x = e.pageX - $(e.currentTarget).offset().left,
        y = e.pageY - $(e.currentTarget).offset().top;

    $(e.currentTarget).find('span.bg').css({
        'left': x,
        'top': y
    });
});

$(document).ready(function () {
    lang = $('html').attr('lang');
    baseURL = $('base').attr('href');
    $('html').addClass('br_' + browser).addClass('os_' + os);

    resize();
    $(window).resize(function () {
        clearTimeout(resizeTo);
        resizeTo = setTimeout(function () {
            resize();
        }, 400);
    });

    scroll();
    $(window).scroll(function () {
        scroll();
    });

    $('body').on('click', 'a', function () {
        var href = $(this).attr('href');

        if (href.indexOf('#') != -1) {
            var id = href.substr(href.indexOf('#'));
            if ($(id).length) {
                $(doc).animate({'scrollTop': $(id).offset().top - $('.navbar').height() - 60}, 'slow');

                return false;
            }
        }
    });

    popup();
    menu();
    submenu();
    backdrop();
    /*$('.navbar-menu').mCustomScrollbar({
        scrollInertia: 0,
        mouseWheel: {preventDefault: true}
    });*/

    if ($('.form-group').length) {
        form();
    }

    if ($('.datepicker').length) {
        var dateFormat = $('.datepicker').data('dateformat');
        $('.datepicker').mask(dateFormat);
    }

    if ($('.form-tax').length) {
        var taxFormat = $('.form-tax').data('taxformat');
        $('.form-tax').mask(taxFormat);
    }

    if ($('textarea').length) {
        autosize($('textarea'));
    }

    if ($('.input-urls').length) {
        addUrl();
    }

    if ($('input[type=password]').length) {
        checkPassword();
    }

    if ($('.input-group-code').length) {
        inputFocus();
    }

    if ($('.input-copy').length) {
        copyValue();
    }

    if ($('.input-check').length) {
        checkInputContent();
        focusInputContent();
    }

    if ($('.input-barion').length) {
        $('.input-barion').mask('000 000 000');
    }

    if ($('.page-dashboard').length) {
        dashboardSlider();
    }

    if ($('.page-settings').length) {
        deleteAddedUrl();
    }

    if ($('.page-help').length) {
        accordion();
    }

    if ($('.page-compare-v2').length) {
        pageCompare();
    }

    if ($('.page-donations').length) {
        pageDonations();
    }

    if ($('.fanpage-donations-slider').length) {
        donationSlider();
    }

    /*  if ($('#addonateprice').length) {
          $('#addonateprice').click(function () {
              $('.input-select').select2("destroy");
              setTimeout(function () {
                  select();
              }, 100);
          });
      }*/

    if ($('.create-notification').length) {
        $('.create-notification').off().on('click', function (e) {
            e.preventDefault();

            var self = $(this),
                textToNoti = $(self).data('text');

            $(self).addClass('disable');

            createNotification(textToNoti, $(this));


            return false;
        });
    }

    if ($('.tab-other-billing').length) {
        paywallBilling();
    }

    closeNotification();

    if ($('.tooltip-trigger').length) {
        tooltip();
    }

    if ($('.page-message').length) {
        sendMessage();
    }

    if ($('#organization').length) {
        /*   showExtraInput();*/
    }

    if ($('.page-fanpage').length) {
        fanpageDonationList();
        fanpageDescription();
        showFanpageShare();
    }

    if ($('.post-list').length) {
        postVideoEmbed();
        postVideoThumbnail();
        postList();

        /*   postReadmore();*/
    }

    if ($('.fanpage-posts').length) {
        /* postVideoThumbnail()*/
    }

    if ($('.page-readpost').length) {
        postVideoEmbed();
        if ($('.page-readpost .music-player').length) {
            audioplayer();
        }
    }

    if ($('.page-writepost').length) {
        writePost();
        writePostSend();
        dzAudioURL();
    }

    if ($('.page-donations').length && $('#osszespwadomanyozott').length) {
        $('#osszespwadomanyozott').appendTo($('#osszespwadomanyozott').siblings('.donation-container'));
    }

    if ($('.page-registration-step1').length) {
        $('#additional-settings').click(function () {
            $('.profil-additional-settings').slideToggle();
        });
    }

    if ($('.js-showcustomblock').length) {
        showCustomBlock();
    }


    document.addEventListener('touchstart', handleTouchStart, false);
    document.addEventListener('touchend', handleTouchEnd, false);

    var xDown = null;
    var yDown = null;
    var firstTouchPos;

    function getTouches(evt) {
        return evt.touches || evt.originalEvent.touches;
    }

    function handleTouchStart(evt) {
        var firstTouch = getTouches(evt)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;

        firstTouchPos = firstTouch.clientX;
    }

    function handleTouchEnd(evt) {
        var endTouchPos = evt.changedTouches[0].clientX;

        if ((firstTouchPos - endTouchPos) >= 100) {
            if ($('html').hasClass('menu-open')) {
                $('html').removeClass('menu-open');
                $('.backdrop').fadeOut();
            }
        }

        if (firstTouchPos - endTouchPos <= -100) {
            if (!$('html').hasClass('menu-open')) {
                $('html').addClass('menu-open');
                $('.backdrop').fadeIn();
            }
        }
    }

    setTimeout(function () {
        $('html').addClass('page-loaded');
    }, 500);
});

function scroll() {
    WW = $(window).width();
    ST = $(window).scrollTop();

    /* create scroll functions */
    animateScroll();

    if (ST < previousTop) {
        //ha fel görgetek

        if (ST > 0) {
            //ha nem a tetején vagyok
        } else {
            //ha a tetjére ért

        }

        if ($('.page-writepost').length) {
            if (ST <= ($('.writty-editor').offset().top + $('.writty-editor').outerHeight() - ($('.navbar').outerHeight() + 50)) && $('.writty-editor').outerHeight() >= 150) {
                $('.page-writepost').addClass('float-toolbar');
            }

            if ((ST + $('.navbar').outerHeight() + 5) <= $('.writty-content').offset().top) {
                $('.page-writepost').removeClass('float-toolbar');
            }
        }
    } else {
        var cat = $('.header-slider').height();

        if (ST > previousTop && ST > cat && !d) {
            //ha le görgetek
            $('html').addClass('scrolled-down');

            lastScrollTop = ST;

            if (scrolldown == 0) {
                setTimeout(function () {
                    $(window).scrollTop($(window).scrollTop() + 1);
                    scrolldown = 1;
                }, 100);
            }
        }

        if ($('.page-writepost').length) {
            if ((ST + $('.navbar').outerHeight() + 5) >= $('.toolbar').offset().top && $('.writty-editor').outerHeight() >= 150) {
                $('.page-writepost').addClass('float-toolbar');
            }
            if (ST >= ($('.writty-editor').offset().top + $('.writty-editor').outerHeight() - ($('.navbar').outerHeight() + 50))) {
                $('.page-writepost').removeClass('float-toolbar');
            }
        }
    }

    previousTop = ST;

    $('.parallaxbg').each(function () {
        var top = $(this).offset().top;
        var h = $(this).outerHeight(true);
        if ((top - WH) < ST && (top + h) > ST) {
            $(this).css('background-position', 'center ' + ((ST - top) * 0.3) + 'px');
        }
    });
}

var mobilSize = 0, submenuOpen = 0, menuLink = 'javascript:void(0)';

function resize() {
    WW = $(window).width();
    WH = $(window).height();
    DH = $(document).height();

    /* create resize functions */
    if (WW <= 768) {
        if ($('.services-item').length) {
            $('.services-item p').removeAttr('style');
        }
    } else {
        if ($('.services-item').length) {
            resizeBoxes('.services-item p');
        }
    }

    if (WW <= 992) {
        if ($('.donation-container').length) {
            $('.donation-container .right p.name').removeAttr('style');
        }

        if ($('.page-tariff').length) {
            $('.page-tariff .tariff-title').removeAttr('style');
        }

        if ($('.recommender').length) {
            $('.recommender-title').removeAttr('style');
        }

        if ($('.creator-slider').length) {
            if ($('.creator-slider').hasClass('slick-initialized')) {
                $('.creator-slider').slick('unslick');
            }
        }

    } else {
        if ($('.donation-container').length) {
            resizeBoxes('.donation-container .right p.name');
        }

        if ($('.page-tariff').length) {
            resizeBoxes('.page-tariff .tariff-title');
        }

        if ($('.recommender').length) {
            resizeBoxes('.recommender-title');
        }

        if ($('.creator-slider').length && !$('.creator-slider').hasClass('slick-initialized')) {
            cretorSlider();
        }
    }

    if (WW <= 1024) {
        if ($('.datepicker').length) {
            datepicker();
        }
    } else {
        if ($('.datepicker').length) {
            $('.datepicker').datepicker('remove');
        }
    }
}
